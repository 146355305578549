import { FuzzySearchResult } from "layout/SiteTopNav/FuzzySearch/utils/types";

import { sendGaSearchEvent } from "common/util/googleAnalytics";

export const fuzzySearch = async (
  query: string,
  uiLocation: string
): Promise<FuzzySearchResult> => {
  const response = await fetch(`/fuzzy-search.json?query=${query}&default_results=false`);
  let results = { locations: [], topics: [] };
  if (response.ok) {
    results = await response.json();
  } else {
    throw new Error(`Error in fuzzy search request: ${response.status}`);
  }
  sendGaSearchEvent({
    category: "Fuzzy Search",
    action: "Search",
    label: query,
    ui_location: uiLocation
  });
  return results;
};
