import { Divider } from "@mui/material";

import FuzzySearchRecents from "./FuzzySearchRecents";

type FuzzySearchModalFooterProps = { showRecentSearches?: boolean; uiLocation: string };
export const FuzzySearchModalFooter: React.FC<FuzzySearchModalFooterProps> = ({
  showRecentSearches = true,
  uiLocation
}) => {
  return (
    <>
      <Divider />
      {showRecentSearches && <FuzzySearchRecents uiLocation={uiLocation} />}
    </>
  );
};
