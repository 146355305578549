import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";

type RotatingPlaceholderType = {
  placeholders: string[];
  rotationEnabled?: boolean;
  rotationTimeInterval?: number;
};

const RotatingPlaceholder: React.FC<RotatingPlaceholderType> = ({
  placeholders = [],
  rotationEnabled = false,
  rotationTimeInterval = 1500
}) => {
  const [activePlaceholderIndex, setActivePlaceholderIndex] = useState<number>(0);

  useEffect(() => {
    rotationEnabled && setActivePlaceholderIndex(1);
  }, [rotationEnabled]);

  useEffect(() => {
    if (!rotationEnabled) return;
    const intervalId = setInterval(() => {
      let newIndex = activePlaceholderIndex + 1;
      if (newIndex > placeholders.length - 1) newIndex = 0;
      setActivePlaceholderIndex(newIndex);
    }, rotationTimeInterval);

    return () => clearInterval(intervalId);
  }, [rotationEnabled, rotationTimeInterval, placeholders.length, activePlaceholderIndex]);

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: "stretch",
        pl: 5.5,
        pr: 1,
        maxHeight: "100%",
        transition: "transform .3s ease-in-out",
        transform: `translatey(-${activePlaceholderIndex * 100}%)`
      }}
    >
      {placeholders.map((text, i) => (
        <Box key={`placeholder-${i}`} display="flex" flex={1} minHeight="100%" alignItems="center">
          <Typography
            variant="body2"
            sx={{
              color: "#999",
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap"
            }}
          >
            {text}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};

export default RotatingPlaceholder;
