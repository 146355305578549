import { NextParsedUrlQuery } from "next/dist/server/request-meta";

import { FuzzySearchTopic } from "./types";

export const topicPathRecreation = (
  topics: FuzzySearchTopic[],
  routerQuery: NextParsedUrlQuery
): FuzzySearchTopic[] => {
  const locationId = routerQuery["locationId"];
  const districtId = routerQuery["districtId"];

  const parsedTopics: FuzzySearchTopic[] = [];
  topics.forEach((topic) => {
    if (locationId !== undefined && typeof locationId === "string") {
      parsedTopics.push({ ...topic, path: topic.path?.replace("state", locationId) });
    } else if (districtId !== undefined && typeof districtId === "string") {
      parsedTopics.push({ ...topic, path: topic.path?.replace("state", districtId) });
    } else {
      parsedTopics.push(topic);
    }
  });
  return parsedTopics;
};
