import { Link as MuiLink, Typography } from "@mui/material";
import Highlighter from "react-highlight-words";

import { FuzzySearchLocation, FuzzySearchTopic } from "./utils/types";

import { sendGaNavigationEvent } from "common/util/googleAnalytics";

import styles from "./FuzzySearchModalListItem.module.css";
import { storeRecentSearch } from "./FuzzySearchRecents";

type FuzzySearchModalListItemProps = FuzzySearchTopic &
  FuzzySearchLocation & {
    uiLocation?: string;
    type?: "Locations" | "Topics";
    searchTerm?: string;
  };

const FuzzySearchModalListItem: React.FC<FuzzySearchModalListItemProps> = ({
  type,
  uiLocation,
  searchTerm,
  ...item
}) => {
  const { highlightedSearchResult } = styles;
  return (
    <MuiLink
      key={item.path}
      href={`${item.path ?? ""}`}
      sx={{
        px: 1,
        py: 0.5,
        lineHeight: 1,
        borderRadius: "4px",
        "&:hover,&:focus": {
          background: "#f5f5f5",
          textDecoration: "none",
          "& mark": { background: "#f5f5f5" }
        }
      }}
      onClick={() => {
        sendGaNavigationEvent({
          category: "Fuzzy Search",
          action: "Select",
          label: item.name ?? "",
          ui_location: uiLocation
        });
        storeRecentSearch({ ...item, type });
      }}
    >
      <Typography
        variant="body2"
        component="span"
        sx={{
          lineHeight: 1,
          textDecoration: "none",
          "&:hover": {
            color: "brand.main"
          }
        }}
        color="primary.main"
      >
        {item.name && searchTerm && (
          <Highlighter
            highlightClassName={highlightedSearchResult}
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={item.name as string}
          />
        )}
      </Typography>
    </MuiLink>
  );
};

export default FuzzySearchModalListItem;
