import { Box, Link as MuiLink, Typography } from "@mui/material";

import { FuzzySearchTopic } from "./utils/types";

import {
  createUpdateLocationPath,
  fuzzySearchRouterContainsException
} from "./utils/locationPathRecreation";
import { sendGaNavigationEvent } from "common/util/googleAnalytics";
import useRouterPath from "common/util/hooks/usePathWithParamKeys";

interface FuzzySearchRecentItem extends FuzzySearchTopic {
  slug?: string | null;
  type?: "Locations" | "Topics";
}

// Update this to clear users' recents if something changes
// in the search result data
export const VERSION = "1";
export const LOCAL_STORAGE_KEY = `recent_searches`;
// Limit of how many records are stored and shown
export const RECENTS_LIMIT = 5;

export const getRecentSearches = (): FuzzySearchRecentItem[] => {
  if (typeof window === "undefined" || !localStorage) return [];
  // Check version, clear if new version
  const version = localStorage.getItem(`${LOCAL_STORAGE_KEY}_version`) as number | string;
  if (!version || version != VERSION) {
    localStorage.setItem(LOCAL_STORAGE_KEY, "[]");
  }
  localStorage.setItem(`${LOCAL_STORAGE_KEY}_version`, VERSION);
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "[]") || [];
};

export const storeRecentSearch = (selection: FuzzySearchRecentItem) => {
  if (typeof window === "undefined" || !localStorage) return;
  const recents = getRecentSearches();
  const existingSelection = recents.find((r: FuzzySearchRecentItem) => r.name === selection.name);
  if (!existingSelection) {
    recents.unshift(selection);
  }
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(recents.slice(0, RECENTS_LIMIT)));
};

const FuzzySearchRecents = ({ uiLocation }: { uiLocation: string }) => {
  const recents = getRecentSearches();
  const { pathWithParamKeys, params } = useRouterPath();

  if (recents.length === 0) {
    return null;
  }

  const pathForItem = (
    path?: string | null,
    slug?: string | null,
    type?: FuzzySearchRecentItem["type"]
  ): string => {
    if (type === "Locations" && slug) {
      const exceptionLocation = fuzzySearchRouterContainsException(pathWithParamKeys);
      const updatedPath = createUpdateLocationPath({
        slug,
        pathname: pathWithParamKeys,
        exceptionLocation,
        routerQuery: params
      });
      return updatedPath;
    }
    const locationPath = slug ? `/locations/${slug as string}/topics` : null;
    return path || locationPath || "#";
  };

  return (
    <Box display="flex" p={2} alignItems="baseline">
      <Typography
        component="span"
        variant="body2"
        sx={{ fontWeight: 600, whiteSpace: "nowrap", marginRight: "5px" }}
      >
        Recent searches:{" "}
      </Typography>
      <Box component="ul" sx={{ display: "inline-flex", flexWrap: "wrap", m: 0, p: 0 }}>
        {recents.map(({ path, name, slug, type }, i: number) => (
          <Typography display="inline-flex" key={i} mr={0.5} component="li" lineHeight={1}>
            <MuiLink
              href={pathForItem(path, slug, type)}
              onClick={() =>
                sendGaNavigationEvent({
                  category: "Fuzzy Search",
                  action: "Select Recent search item",
                  label: name ?? "",
                  ui_location: uiLocation
                })
              }
            >
              <Typography variant="body2" component="span">
                {name}
              </Typography>
            </MuiLink>
            {recents.length - 1 !== i ? ", " : ""}{" "}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default FuzzySearchRecents;
