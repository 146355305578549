import { useMemo } from "react";
import { Link as MuiLink, Stack, Typography } from "@mui/material";
import take from "lodash/take";

import { FuzzySearchLocation, FuzzySearchTopic } from "./utils/types";

import FuzzySearchModalListItem from "layout/SiteTopNav/FuzzySearch/FuzzySearchModalListItem";

interface BaseProps {
  searchTerm: string;
  uiLocation: string;
  searching?: boolean;
}

interface PropsForLocation extends BaseProps {
  items: FuzzySearchLocation[];
  type: "Locations";
}

interface PropsForTopics extends BaseProps {
  items: FuzzySearchTopic[];
  type: "Topics";
}

const searchHelpers = {
  locations: "Try searching for your town/city, county, ZIP Code, census tract, etc.",
  topics:
    "Try searching for public health topics like Asthma, Drug Overdose Deaths, Climate Change, etc."
};

export const FuzzySearchModalList: React.FC<PropsForLocation | PropsForTopics> = ({
  items,
  type,
  searchTerm = "",
  uiLocation,
  searching = false
}) => {
  // Render first 8 items returned from API
  const limitedItems = useMemo(() => take(items, 8), [items]);
  const width = type === "Locations" ? "45%" : "55%";
  const helperNode = useMemo(
    () => (
      <Typography variant="body2" component="span" color="action.disabled">
        {type === "Topics" && (
          <>
            <Typography variant="body2" component="span">
              {searchHelpers.topics}
            </Typography>
            <Typography variant="body2" component="span">
              {" "}
              Or review the <MuiLink href="locations/state/topics">list of topics here</MuiLink>.
            </Typography>
          </>
        )}
        {type === "Locations" && searchHelpers.locations}
      </Typography>
    ),
    [type]
  );
  return (
    <Stack gap={1} sx={{ flex: `0 1 ${width}`, width: { xs: "100%", md: width } }}>
      <Typography id="modal-modal-title" variant="h4" component="h3" fontWeight="700">
        {type}
      </Typography>
      {!searching && items.length === 0 && searchTerm.length > 2 && (
        <Stack gap={1}>
          <Typography variant="body2" component="span" color="action.disabled" fontStyle="italic">
            We couldn&lsquo;t find any {type.toLowerCase()} matching &quot;{searchTerm}&quot;.
          </Typography>
          {helperNode}
        </Stack>
      )}
      {items.length === 0 && searchTerm.length < 3 && helperNode}
      <Stack>
        {limitedItems.map((item, i) => (
          <FuzzySearchModalListItem
            searchTerm={searchTerm}
            key={`${type}-search-result-${i}`}
            uiLocation={uiLocation}
            {...item}
          />
        ))}
      </Stack>
    </Stack>
  );
};
