export const getEnv = () => process.env.ENVIRONMENT;
export const envIsDev = () => getEnv() === "dev";
export const envIsDemo = () => getEnv() === "demo";
export const envIsProd = () => getEnv() === "prod";
export const envIsStaging = () => getEnv() === "staging";

type FeatureFlagKeys = "cprExportEnabled" | "needsAttentionEnabled" | "showSearchBarFooter";

export const featureFlags: Record<FeatureFlagKeys, boolean> = {
  cprExportEnabled: envIsDev(),
  showSearchBarFooter: !envIsProd() && !envIsStaging(),
  needsAttentionEnabled: false
};
